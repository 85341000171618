import React, { useState } from 'react';
import { Link } from 'gatsby';

import Flex from '../base/Flex';
import Box from '../base/Box';
import Button from '../Button';
import FormGroup from '../FormGroup';
import Typography from '../base/Typography';
import CancelDonationEmailForm from './CancelDonationEmailForm';
import DonationsContainer from './DonationsContainer';
import Spinner from '../Spinner';
import donationDraw from '../../../images/donation-draw.svg';

const CancelDonation = ({
  setServerError,
  subscriptionList,
  cancelHandler,
}) => {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  return (
    <Flex
      width="full"
      flexDirection={['column', null, 'row']}
      alignItems="center"
      justifyContent="space-evenly"
      my={4}
      boxShadow="0 0 10px rgba(0, 0, 0, 0.1)"
      p={4}
    >
      {loading ? (
        <Box pt="30px" pb="50px" width="50%" textAlign="center">
          <Spinner size="huge" />
        </Box>
      ) : !subscriptionList ? (
        <Box width={[1, 1, 1 / 2]} px={[0, 0, 2, 4]} pb={4} mr={3}>
          {!success ? (
            <CancelDonationEmailForm
              setServerError={setServerError}
              setLoading={setLoading}
              setSuccess={setSuccess}
            />
          ) : (
            <Typography fontSize="4">
              Er is een e-mail verstuurd naar het opgegeven e-mailadres.
              Controleer je mailbox en volg de instructies.
            </Typography>
          )}
          <FormGroup>
            <Link to="/donation">
              <Button type="button" color="background">
                Terug naar donatie
              </Button>
            </Link>
          </FormGroup>
        </Box>
      ) : (
        <DonationsContainer
          data={subscriptionList}
          cancelHandler={cancelHandler}
        />
      )}
      {(!subscriptionList || subscriptionList.length < 5) && (
        <Box width={[1, 1, 1 / 2]}>
          <img width="100%" src={donationDraw} alt="donation" />
        </Box>
      )}
    </Flex>
  );
};

export default CancelDonation;
