import React, { useState } from 'react';

import Flex from '../base/Flex';
import Button from '../Button';
import Typography from '../base/Typography';
import DonationCard from './DonationCard';
import FormGroup from '../FormGroup';

const DonationsContainer = ({ data, cancelHandler }) => {
  const [selectedCardId, setSelectedCardId] = useState(null);
  const [subscriptionToCancel, setSubscriptionToCancel] = useState(null);

  return (
    <Flex
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      bg="primaryLight"
      width={['full', null, data.length < 5 ? 1 / 2 : 'full']}
      height="full"
      py="3"
      mr="3"
      borderRadius="3"
    >
      {data.length > 0 ? (
        <>
          <Typography>Selecteer een periodieke betaling om te annuleren:</Typography>
          <Flex
            flexDirection={['column', 'row']}
            flexWrap={['nowrap', 'wrap']}
            justifyContent="center"
            alignItems="baseline"
          >
            {data.map(subscription => (
              <DonationCard
                key={subscription.id}
                id={subscription.id}
                data={subscription}
                setSubscriptionToCancel={setSubscriptionToCancel}
                setSelectedCardId={setSelectedCardId}
                selectedCardId={selectedCardId}
              />
            ))}
          </Flex>
          {subscriptionToCancel && (
            <FormGroup>
              <Button
                color="background"
                fontWeight="semibold"
                onClick={() => cancelHandler(subscriptionToCancel)}
              >
                Annuleer de geselecteerde donatie
              </Button>
            </FormGroup>
          )}
        </>
      ) : (
        <Typography>Geen periodieke betaling gevonden</Typography>
      )}
    </Flex>
  );
};

export default DonationsContainer;
