import React from 'react';
import styled from 'styled-components';
import Box from './base/Box';
import Button from './Button';
import Icon from './Icon';

const StyledAlert = styled(Box)`
  position: relative;
  font-weight: bold;
  text-align: center;

  background-color: ${({ variant }) =>
    variant === 'danger'
      ? '#ff7f7f'
      : variant === 'success'
      ? '#a7e2af'
      : variant === 'warning'
      ? '#e2d3a7'
      : 'gray'};

  color: ${({ variant }) =>
    variant === 'danger'
      ? '#a05154'
      : variant === 'success'
      ? '#508253'
      : variant === 'warning'
      ? '#937930'
      : '#ffffff'};

  & button {
    position: absolute;
    right: 5px;
    top: 5px;
  }
`;

const Alert = props => {
  return (
    <>
      {props.open && (
        <StyledAlert {...props} p={3}>
          {props.text ? props.text : props.children}
          <Button onClick={props.onClose} bg="transparent" color="inherit">
            <Icon name="close" />
          </Button>
        </StyledAlert>
      )}
    </>
  );
};

export default Alert;
