import React from 'react';
import { useForm } from 'react-hook-form';
import axios from 'axios';

import Button from '../Button';
import FormControl from '../FormControl';
import FormGroup from '../FormGroup';
import Typography from '../base/Typography';

import * as yup from 'yup';

const FormSchema = yup.object().shape({
  email: yup
    .string()
    .email('E-mail is niet geldig')
    .required('E-mail mag niet leeg zijn'),
});

const CancelDonationForm = ({ setServerError, setLoading, setSuccess }) => {
  const { register, errors, handleSubmit, setError } = useForm({
    mode: 'onBlur',
    validationSchema: FormSchema,
  });

  const onSubmit = async formData => {
    try {
      setLoading(true);
      await axios.get(
        `${process.env.GATSBY_SERVER_URL}/customer/${formData.email}`,
      );
      setSuccess(true);
    } catch (error) {
      console.error(
        'SERVER ERROR',
        `Error code: ${error.response && error.response.status}`,
        `Error message: ${error.message ||
          error.response.data.message ||
          error.response.data}`,
      );
      setServerError(error.response || error);
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Typography textAlign="center" fontSize="4">
        Geef een e-mailadres op
      </Typography>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormGroup>
          <FormControl
            ref={register}
            name="email"
            type="email"
            placeholder="Email"
            notValid={errors.email}
          />
          {errors.email && <span>{errors.email.message}</span>}
        </FormGroup>
        <FormGroup>
          <Button type="submit" color="background">
            Verstuur
          </Button>
        </FormGroup>
      </form>
    </>
  );
};

export default CancelDonationForm;
