import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import Box from '../base/Box';
import Typography from '../base/Typography';

const Card = styled(Box)`
  cursor: ${({ isActive }) => (isActive ? 'pointer' : 'not-allowed')};
`;

const Title = styled(Typography)`
  &::after {
    content: '';
    display: block;
    border-bottom: 1px solid rgba(109, 19, 152, 0.3);
  }
`;

const DonationCard = ({
  data,
  id,
  setSubscriptionToCancel,
  selectedCardId,
  setSelectedCardId,
}) => {
  const [hovered, setHovered] = useState(false);
  const [isSelected, setIsSelected] = useState(false);
  const isActive = data.status === 'active';

  useEffect(() => {
    if (selectedCardId === id && isActive) {
      setIsSelected(true);
    } else {
      setIsSelected(false);
    }
  }, [selectedCardId, id, isActive]);

  const clickHandler = () => {
    if (isActive) {
      setSubscriptionToCancel(data);
      setSelectedCardId(id);
    }
  };

  return (
    <Card
      onClick={clickHandler}
      boxShadow="1"
      width=" 200px"
      minHeight="300px"
      m="3"
      borderRadius="22px"
      bg="background"
      overflow="hidden"
      isActive={isActive}
      onMouseEnter={() => isActive && setHovered(true)}
      onMouseLeave={() => isActive && setHovered(false)}
    >
      <Box
        p="3"
        minHeight="250px"
        borderTopLeftRadius="22px"
        borderTopRightRadius="22px"
        borderLeft="3px solid"
        borderRight="3px solid"
        borderTop="3px solid"
        borderColor={
          isSelected ? '#F18321' : hovered ? '#6E019A' : 'transparent'
        }
      >
        <Title fontWeight="semibold" textAlign="center" mt="0" mb="2">
          {data.status.charAt(0).toUpperCase() + data.status.slice(1)}
        </Title>
        <Typography fontWeight="semibold" textAlign="center" my="0">
          Omschrijving
        </Typography>
        <Typography textAlign="center" my="0">
          {data.description}
        </Typography>
        <Typography fontWeight="semibold" textAlign="center" mb="0">
          Interval
        </Typography>
        <Typography textAlign="center" my="0">
          Iedere {data.interval}
        </Typography>
        {data.nextPaymentDate && (
          <>
            <Typography fontWeight="semibold" textAlign="center" mb="0">
              Volgende betaling
            </Typography>
            <Typography textAlign="center" my="0">
              {data.nextPaymentDate}
            </Typography>
          </>
        )}
      </Box>
      <Box
        bg={isSelected ? 'secondary' : hovered ? 'primary' : 'secondaryOpaque'}
        m="0"
        p="3"
        height="full"
        borderLeft="3px solid"
        borderRight="3px solid"
        borderColor={
          isSelected ? '#F18321' : hovered ? '#6E019A' : 'transparent'
        }
      >
        <Typography
          fontWeight="500"
          textAlign="center"
          m="0"
          color={!isSelected ? (hovered ? 'white' : 'inherit') : 'white'}
        >
          {new Intl.NumberFormat('nl-NL', {
            style: 'currency',
            currency: data.amount.currency,
          }).format(data.amount.value)}
        </Typography>
      </Box>
    </Card>
  );
};

export default DonationCard;
