import React, { useState, useEffect } from 'react';
import queryString from 'query-string';
import axios from 'axios';

import Layout from '../../components/layout';
import SEO from '../../components/seo';
import Alert from '../../components/styled/Alert';
import Spinner from '../../components/styled/Spinner';
import Box from '../../components/styled/base/Box';
import Typography from '../../components/styled/base/Typography';
import CancelDonation from '../../components/styled/cancelDonation/CancelDonation';
import ErrorReport from '../../components/styled/ErrorReport';

import handleError from '../../util/handleError';

const CancelDonationPage = ({ location }) => {
  const { customerId, email } = queryString.parse(location.search);

  const [success, setSuccess] = useState(false);
  const [subscriptionList, setSubscriptionList] = useState(null);
  const [loading, setLoading] = useState(false);
  const [serverError, setServerError] = useState(null);

  useEffect(() => {
    if (customerId && email) {
      getSubscriptions(customerId, email);
    }
  }, [customerId, email]);

  const getSubscriptions = async (customerId, email) => {
    try {
      setLoading(true);
      const response = await axios.get(
        `${process.env.GATSBY_SERVER_URL}/subscription/list/${customerId}/${email}`,
      );
      setSubscriptionList(response.data._embedded.subscriptions);
    } catch (error) {
      handleError(error, setServerError);
    } finally {
      setLoading(false);
    }
  };

  const cancelHandler = async subscription => {
    try {
      const isConfirmed = window.confirm(
        'Weet je zeker dat je de periodieke overboeking wil annuleren?',
      );
      if (isConfirmed && subscription.status === 'active') {
        setLoading(true);
        await axios.delete(
          `${process.env.GATSBY_SERVER_URL}/subscription/${customerId}/${subscription.id}/${email}`,
        );
        window.scrollTo(0, 0);
        setSuccess(true);
        getSubscriptions(customerId, email);
      }
    } catch (error) {
      handleError(error, setServerError);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Layout>
      <SEO title="Cancel Subscription" />
      <Typography color="primary" as="h1">
        Periodieke overboeking annuleren
      </Typography>
      {loading && (
        <Box py="30px" textAlign="center">
          <Spinner size="huge" />
        </Box>
      )}
      {serverError && (
        <ErrorReport
          serverError={serverError}
          setServerError={setServerError}
        />
      )}
      <Alert
        open={success}
        onClose={() => setSuccess(false)}
        mb={3}
        variant="success"
        text="De periodieke overboeking is succesvol geannuleerd."
      />
      {!loading && !serverError && (
        <CancelDonation
          setServerError={setServerError}
          subscriptionList={subscriptionList}
          cancelHandler={cancelHandler}
        />
      )}
    </Layout>
  );
};

export default CancelDonationPage;
